import {createApi} from "@reduxjs/toolkit/query/react";
import {dynamicBaseQuery} from "@sv-studios/react-utils";
import {LoggingConfiguration, LoggingConfigurationMutation} from "@/model/api/admin/LoggerModel";
import {
    DeleteEntityParams,
    DeleteEntityResponse,
    GetEntitiesResponse,
    GetEntityParams,
    GetEntityResponse,
    UpdateEntityParams,
    UpdateEntityResponse
} from "@/model/api/admin/EntityModel";
import {GetInfoResponse} from "@/model/api/admin/SystemInfoModel";
import {GetHealthResponse} from "@/model/api/admin/SystemHealthModel";
import {GetMappingsResponse} from "@/model/api/admin/MappingsModel";
import {GetEnvironmentResponse} from "@/model/api/admin/EnvironmentModel";
import {GetLogsParams, GetLogsResponse} from "@/model/api/admin/LogsModel";


export const adminApi = createApi({
    reducerPath: "adminApi",
    baseQuery: dynamicBaseQuery,
    tagTypes: ["entity", "info", "health", "mappings", "logs", "env", "loggers"],
    endpoints: (builder) => ({
        getEntities: builder.query<GetEntitiesResponse, void>({
            query: () => ({
                url: "api/admin/entity",
                credentials: "include",
                mode: "cors",
                redirect: "manual",
            }),
        }),
        getLoggers: builder.query<LoggingConfiguration, void>({
            query: () => ({
                url: "management/loggers",
                credentials: "include",
                mode: "cors",
                redirect: "manual",
            }),
            providesTags: ["loggers"],
        }),
        updateLogger: builder.mutation<void, LoggingConfigurationMutation>({
            query: ({logger, level}) => ({
                url: `management/loggers/${logger}`,
                credentials: "include",
                mode: "cors",
                redirect: "manual",
                method: "POST",
                body: {
                    configuredLevel: level,
                },
            }),
            invalidatesTags: ["loggers"],
        }),
        getEntity: builder.query<GetEntityResponse, GetEntityParams>({
            query: ({entity, page = 0, size = 20, search = "", sort = ""}) => ({
                url: `api/admin/entity/${entity}/search?page=${page}&size=${size}${sort}`,
                credentials: "include",
                mode: "cors",
                redirect: "manual",
                method: "POST",
                body: {
                    query_string: {
                        query: search + "*",
                    },
                },
            }),
            providesTags: (result, test, query) => [
                {
                    type: "entity",
                    id:
                        query.entity +
                        " " +
                        result?.number +
                        " " +
                        result?.size,
                },
            ],
        }),
        updateEntity: builder.mutation<
            UpdateEntityResponse,
            UpdateEntityParams
        >({
            query: ({entity, body, id}) => ({
                url: `api/admin/entity/${entity}/${id}`,
                credentials: "include",
                mode: "cors",
                redirect: "manual",
                method: "PUT",
                body: body,
            }),
            invalidatesTags: ["entity"],
        }),
        deleteEntity: builder.mutation<DeleteEntityResponse, DeleteEntityParams>({
            query: ({entity, id}) => ({
                url: `api/admin/entity/${entity}/${id}`,
                credentials: "include",
                mode: "cors",
                redirect: "manual",
                method: "DELETE",
            }),
            invalidatesTags: ["entity"],
        }),
        getInfo: builder.query<GetInfoResponse, void>({
            query: () => ({
                url: `management/info`,
                credentials: "include",
                mode: "cors",
                redirect: "manual",
            }),
            providesTags: ["info"],
        }),
        getHealth: builder.query<GetHealthResponse, void>({
            query: () => ({
                url: `management/health`,
                credentials: "include",
                mode: "cors",
                redirect: "manual",
            }),
            providesTags: ["health"],
        }),
        getMappings: builder.query<GetMappingsResponse, void>({
            query: () => ({
                url: `management/mappings`,
                credentials: "include",
                mode: "cors",
                redirect: "manual",
            }),
            providesTags: ["mappings"],
        }),
        getLogs: builder.query<GetLogsResponse, GetLogsParams>({
            query: ({lastDate}) => ({
                url: `management/logs${lastDate ? `?startDate=${lastDate}` : ""}`,
                credentials: "include",
                mode: "cors",
                redirect: "manual",
            }),
        }),
        getEnv: builder.query<GetEnvironmentResponse, void>({
            query: () => ({
                url: `management/env`,
                credentials: "include",
                mode: "cors",
                redirect: "manual",
            }),
        }),
    }),
});

export const {
    useGetEntitiesQuery,
    useGetEntityQuery,
    useGetInfoQuery,
    useGetHealthQuery,
    useGetMappingsQuery,
    useGetLogsQuery,
    useGetEnvQuery,
    useGetLoggersQuery,
    useUpdateLoggerMutation,
    useUpdateEntityMutation,
    useDeleteEntityMutation,
} = adminApi;
