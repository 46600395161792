import React from "react";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
  Input,
  Radio,
  Textarea,
} from "@material-tailwind/react";
import {useDispatch, useSelector} from "react-redux";
import {adminActions} from "@/store/admin/admin-slice";
import {useDeleteEntityMutation, useUpdateEntityMutation,} from "@/store/admin/admin-api";
import {ApiError, Toaster} from "@sv-studios/react-utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-solid-svg-icons";
import {useAppSelector} from "@/store/redux-utils";

export function EntityDialog() {
  const open = useAppSelector((state) => state.admin.entityDialog.open);
  const entity = useAppSelector((state) => state.admin.entityDialog.entity);
  const edit = useAppSelector((state) => state.admin.entityDialog.edit);
  const type = useAppSelector((state) => state.admin.entityDialog.type);
  const dispatch = useDispatch();

  const [updateEntity, {}] = useUpdateEntityMutation();

  const [deleteEntity, {}] = useDeleteEntityMutation();

  const close = () => {
    dispatch(
      adminActions.setEntityDialog({ open: false, entity: {}, edit: false })
    );
  };

  const switchEdit = () => {
    dispatch(
      adminActions.setEntityDialog({
        edit: !edit,
        entity: entity,
        open: true,
        type: type,
      })
    );
  };

  const submitDelete = () => {
    confirm(`Soll die Entität [${entity.id}] wirklich gelöscht werden?`) &&
      deleteEntity({ entity: type, id: entity.id }).catch((e) => {
        Toaster.error("Fehler beim Löschen der Entität");
      });
  };

  const fields = Object.entries(entity);

  // submit from form data
  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const data: { [key: string]: any } = {};
    const handledNames = new Set(); // Für Radio-Buttons und möglicherweise andere Widgets, die mehrfach vorkommen

    for (const [key, value] of formData.entries()) {
      // Verhindern, dass bereits behandelte Namen erneut bearbeitet werden
      if (handledNames.has(key)) {
        continue;
      }

      const inputElements = event.currentTarget[key];

      // Wenn es sich um eine RadioNodeList handelt
      if (inputElements instanceof RadioNodeList) {
        for (const element of inputElements ) {
          const radio = element as HTMLInputElement;
          if (radio.checked) {
            data[key] = radio.value;
            break;
          }
        }
        handledNames.add(key); // Wir haben dieses Set an Radio-Buttons abgearbeitet
        continue;
      }

      // Typ aus dem Datenattribut abrufen oder auf 'string' setzen, falls nicht vorhanden
      const type = inputElements.dataset.type || "string";

      switch (type) {
        case "object":
          data[key] = JSON.parse(String(value));
          break;
        case "number":
          data[key] = Number(value);
          break;
        case "boolean":
          data[key] = value === "true";
          break;
        default:
          data[key] = value; // Behandeln Sie es als String
      }
    }

    const id = formData.get("id") as string;
    if(!id) {
        return Toaster.error("Fehler beim Aktualisieren der Entität", {
            data: {
            error: "id not found",
            },
        });
    }

    updateEntity({ entity: type, id: id, body: data }).unwrap()
      .then((e) => {
        if (e.error)
          return Toaster.error("Fehler beim Aktualisieren der Entität", {
            data: {
              error: e.error,
            },
          });
        Toaster.success("Entität erfolgreich aktualisiert");
      })
        .catch(({data}: { data: ApiError }) => {
        Toaster.error("Fehler beim Aktualisieren der Entität", {
          data: {
            error: data,
          },
        });
      });
  };

  const reformattedFields = ([key, value]: [string, any]) => {
    switch (typeof value) {
      case "object":
        return (
          <Textarea
            defaultValue={JSON.stringify(value, undefined)}
            disabled={!edit}
            name={key}
            data-type="object"
          />
        );
      case "number":
        return (
          <Input
            disabled={!edit}
            type="number"
            defaultValue={value}
            name={key}
            data-type="number"
          />
        );
      case "boolean":
        return (
          <>
            <Radio
              value={"true"}
              name={key}
              disabled={!edit}
              defaultChecked={value}
              label="true"
              data-type="boolean"
            />
            <Radio
              value={"false"}
              name={key}
              disabled={!edit}
              defaultChecked={!value}
              label="false"
              data-type="boolean"
            />
          </>
        );
      case "string":
        return (
          <Input
            disabled={!edit}
            type="text"
            defaultValue={value}
            name={key}
            data-type="string"
          />
        );
    }
    return <span>{value}</span>;
  };

  const formattedData = ([key, value]: [string, any], index: number) => {
    return (
      <>
        <div className="text-gray-500" key={index + "key"}>
          {key}
        </div>
        <div className="md:col-span-2" key={index + "value"}>
          {reformattedFields([key, value])}
        </div>
      </>
    );
  };

  return (
    <>
      <Dialog open={open} size={"lg"} handler={close}>
        <form onSubmit={submit}>
          <DialogHeader>
            Edit Entity
            <IconButton
              variant="text"
              size="sm"
              color="white"
              onClick={close}
              className="ml-auto"
            >
              <FontAwesomeIcon
                icon={faXmark}
                size="xl"
                className="text-white"
              />
            </IconButton>
          </DialogHeader>
          <DialogBody divider className="grid grid-cols-1 gap-2 md:grid-cols-3">
            {fields.map(formattedData)}
          </DialogBody>
          <DialogFooter className="flex flex-row gap-2">
            <Button
              variant="text"
              color="blue-gray"
              className="mr-auto"
              onClick={close}
            >
              <span>Abbrechen</span>
            </Button>
            <Button variant="outlined" color="red" onClick={submitDelete}>
              Löschen
            </Button>
            {edit ? (
              <Button variant="outlined" color="blue" onClick={switchEdit}>
                <span>Anschauen</span>
              </Button>
            ) : (
              <Button variant="gradient" color="blue" onClick={switchEdit}>
                <span>Bearbeiten</span>
              </Button>
            )}
            {edit && (
              <Button variant="gradient" color="blue" type="submit">
                <span>Speichern</span>
              </Button>
            )}
          </DialogFooter>
        </form>
      </Dialog>
    </>
  );
}
