const knownToggles = {
    "url.frontend.experimentSwitch": "Aktivieren diesen Experimente-Dialog",
    "url.frontend.statistics": "Aktiviere eine Statistic Seite auf welcher du alle kürzlichen Aufrufe siehst.",
    "url.frontend.account.wizard": "Aktiviere auf der Account Seite die Möglichkeit ein Setup für den API Key zu machen.",
    "url.frontend.languageSwitch": "Füge zum Footer ein Sprachauswahl Menü hinzu.",
};

export enum ExperimentToggles {
    ExperimentSwitch = "url.frontend.experimentSwitch",
    Statistics = "url.frontend.statistics",
    AccountWizard = "url.frontend.account.wizard",
    LanguageSwitch = "url.frontend.languageSwitch",
}

export function getToggleDescription(toggle: ExperimentToggles): string {
    return knownToggles[toggle];
}

export function isExperimentToggle(value: any): value is ExperimentToggles {
    return Object.values(ExperimentToggles).includes(value);
}
