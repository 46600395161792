import {useGetMappingsQuery} from "@/store/admin/admin-api";
import {useEffect, useRef, useState} from "react";
import {Button, Card, CardBody, Chip, Collapse, Input,} from "@material-tailwind/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/pro-solid-svg-icons";
import {DispatcherServletEntry} from "@/model/api/admin/MappingsModel";


enum MethodColor {
    GET = "green",
    POST = "brown",
    PUT = "amber",
    PATCH = "purple",
    DELETE = "red",
    ELSE = "blue-gray",
}

export function Mappings() {
    const [showRaw, setShowRaw] = useState(false);
    const [filter, setFilter] = useState("");
    const [search, setSearch] = useState("");
    const {data} = useGetMappingsQuery();

    const inputRef = useRef<HTMLInputElement>(null);

    const mappings =
        data?.contexts?.palm?.mappings?.dispatcherServlets?.dispatcherServlet || [];

    const filterFunc = (mapping: DispatcherServletEntry) => {
        if (!mapping.details) return false;

        let searchMatch = true;
        let filterMatch = true;

        if (search) {
            searchMatch = mapping?.details?.requestMappingConditions?.patterns
                ?.join(", ")
                ?.toLowerCase()
                ?.includes(search?.toLowerCase()) || false;
        }

        if (filter) {
            filterMatch =
                mapping?.details?.requestMappingConditions?.methods?.includes(filter) || false;
        }

        return searchMatch && filterMatch;
    };

    const sortFunc = (a: DispatcherServletEntry, b: DispatcherServletEntry) => {
        if (!a.details || !b.details) return 0;
        if (
            (a.details.requestMappingConditions.patterns?.join(", ") || "") <
            (b.details.requestMappingConditions.patterns?.join(", ") || "")
        )
            return -1;
        return 1;
    };

    useEffect(() => {
        // Handler, um auf Tastendruck zu reagieren
        function handleKeyPress(e: KeyboardEvent) {
            inputRef.current?.focus();
        }

        // Event Listener hinzufügen
        document.addEventListener("keypress", handleKeyPress);

        // Event Listener entfernen, wenn die Komponente nicht mehr gemountet ist
        return () => {
            document.removeEventListener("keypress", handleKeyPress);
        };
    }, [inputRef]);

    return (
        <>
            <Card>
                <CardBody className="flex flex-row justify-between gap-2 p-4">
                    <Button
                        variant="gradient"
                        size="sm"
                        onClick={() => setShowRaw(!showRaw)}
                    >
                        {showRaw ? "Hide" : "Show"} Raw
                    </Button>
                    <div className="flex gap-2 flex-wrap">
                        {(Object.keys(MethodColor) as Array<keyof typeof MethodColor>).map((method) => (
                            <Button
                                variant={filter === method ? "gradient" : "outlined"}
                                color={MethodColor[method] || MethodColor.ELSE}
                                size="sm"
                                className="rounded-full"
                                onClick={() => setFilter(method)}
                            >
                                {method}
                            </Button>
                        ))}
                    </div>
                    <Button
                        variant={filter === "" ? "gradient" : "outlined"}
                        color={MethodColor.ELSE}
                        size="sm"
                        onClick={() => setFilter("")}
                    >
                        <FontAwesomeIcon icon={faTrashCan} className="mr-2"/>
                        Clear Filter
                    </Button>
                </CardBody>
            </Card>
            <Card className="my-2">
                <CardBody className="p-4">
                    <Input
                        label="Search"
                        type="search"
                        size="lg"
                        inputRef={inputRef}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </CardBody>
            </Card>
            <Collapse open={showRaw}>
                <Card className="mb-2">
                    <CardBody className="max-h-screen overflow-auto">
                        <pre>{JSON.stringify(mappings.filter(filterFunc), null, "\t")}</pre>
                    </CardBody>
                </Card>
            </Collapse>
            <Card>
                <CardBody className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 overflow-x-auto">
                    {[...mappings]
                        .filter(filterFunc)
                        ?.sort(sortFunc)
                        .map((mapping) => (
                            <div className="row flex gap-2">
                                {mapping.details?.requestMappingConditions?.patterns?.join(
                                    ", "
                                )}
                                {(mapping.details?.requestMappingConditions?.methods as Array<keyof typeof MethodColor>)?.map(
                                    (method) => (
                                        <a
                                            target={"_blank"}
                                            className="ml-auto"
                                            href={
                                                method === "GET"
                                                    ? mapping.details?.requestMappingConditions?.patterns?.join(
                                                        ", "
                                                    )
                                                    : ""
                                            }
                                        >
                                            <Chip
                                                value={method}
                                                color={MethodColor[method] || MethodColor.ELSE}
                                                className="w-auto"
                                            />
                                        </a>
                                    )
                                )}
                            </div>
                        ))}
                </CardBody>
            </Card>
        </>
    );
}
