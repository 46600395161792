import {useGetLogsQuery} from "@/store/admin/admin-api";
import {Button, Card, CardBody, CardFooter, IconButton, Input,} from "@material-tailwind/react";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faRotate, faScroll, faTrashCan,} from "@fortawesome/pro-solid-svg-icons";

/**
 *
 * @param {Date} date
 */
const convertJsToJavaDate = (date : Date) => {
  const timezoneOffsetMinutes = date.getTimezoneOffset(); // Zeitzone-Abstand in Minuten

  // Aktuelles Datum mit Zeitzone-Abstand
  const formattedDate = new Date(
    date.getTime() - timezoneOffsetMinutes * 60 * 1000
  ).toISOString();

  return formattedDate.replace("Z", "");
};

export function Logs() {
  const [startDate, setStartDate] = useState(new Date());
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [autoScroll, setAutoScroll] = useState(true);
  const [isScrolledUp, setIsScrolledUp] = useState(false);
  const [logsCache, setLogsCache] = useState<string[]>([]);
  const [lastTimestamp, setLastTimestamp] = useState("");
  const [lastDateToQuery, setLastDateToQuery] = useState(convertJsToJavaDate(new Date()));

  const { data } = useGetLogsQuery({ lastDate: lastDateToQuery });

  useEffect(() => {
    if (!data) return;
    setLastTimestamp(data.timestamp);
    setLogsCache((prev) => [...prev, data.logs]);
  }, [data]);

  useEffect(() => {
    setLogsCache([]);
    setLastDateToQuery(convertJsToJavaDate(startDate));
  }, [startDate]);

  useEffect(() => {
    if (!autoRefresh) return;
    const timeout = setTimeout(() => {
      setLastDateToQuery(lastTimestamp);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [autoRefresh, lastTimestamp]);

  useEffect(() => {
    if (!autoScroll) return;
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "auto",
    });
  }, [autoScroll, logsCache]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const pageHeight = document.documentElement.scrollHeight;
      const scrollPercentage = (scrollPosition / pageHeight) * 100;

      if (scrollPercentage < 50) {
        setIsScrolledUp(true);
      } else {
        setIsScrolledUp(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToOpposite = () => {
    window.scrollTo({
      top: isScrolledUp ? document.body.scrollHeight : 0,
      behavior: "instant",
    });
  };

  return (
    <>
      <Card>
        <div className="flex flex-row justify-between border-b p-2 dark:border-panel-600">
          <div className="md:w-[50%]">
            <Input
              type="datetime-local"
              label="Startdatum"
              value={
                (startDate &&
                  new Date(
                    startDate.getTime() - startDate.getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .slice(0, -1)) ||
                ""
              }
              onChange={(e) => {
                const fakeUtcTime = new Date(`${e.target.value}Z`);
                const d = new Date(
                  fakeUtcTime.getTime() +
                    fakeUtcTime.getTimezoneOffset() * 60000
                );
                setStartDate(d);
              }}
            />
          </div>
          <Button variant="outlined" onClick={scrollToOpposite}>
            Ans Ende springen
          </Button>
        </div>
        <CardBody className="overflow-x-auto whitespace-pre">
          <code>{logsCache.join("") || "Es gibt noch keine Logs"}</code>
        </CardBody>
        <CardFooter className="border-t dark:border-t-panel-600">
          Zuletzt aktualisiert: {new Date(lastTimestamp).toLocaleString()}{" "}
          <br />
        </CardFooter>
      </Card>
      <div className="fixed bottom-0 right-0 flex flex-col gap-2 p-2">
        <IconButton
          size="md"
          className="rounded-full"
          title="Clear Logs"
          disabled={logsCache.length === 0}
          onClick={() => setLogsCache([])}
        >
          <FontAwesomeIcon icon={faTrashCan} size="lg" />
        </IconButton>
        <IconButton
          size="md"
          title="Auto Scroll"
          className="rounded-full"
          onClick={() => setAutoScroll(!autoScroll)}
        >
          <FontAwesomeIcon icon={faScroll} size="lg" fade={autoScroll} />
        </IconButton>
        <IconButton
          title="Auto Refresh"
          size="md"
          className="rounded-full"
          onClick={() => setAutoRefresh(!autoRefresh)}
        >
          <FontAwesomeIcon icon={faRotate} size="lg" spin={autoRefresh} />
        </IconButton>
        <IconButton
          size="md"
          title={isScrolledUp ? "Scroll Down" : "Scroll Up"}
          className="rounded-full"
          onClick={scrollToOpposite}
        >
          <FontAwesomeIcon
            icon={isScrolledUp ? faArrowDown : faArrowUp}
            size="lg"
          />
        </IconButton>
      </div>
    </>
  );
}
