import {Alert} from "@material-tailwind/react";

export function AdminOverview() {
  return (
    <>
      <Alert color="deep-purple">
        Du bist ein Admin. Du kannst alles! Wähle links eine Kategorie aus.
      </Alert>
    </>
  );
}
